import { api } from "../../services/api";

export const state = {
	currentCondominium: {
		name: "",
		cnpj: "",
		cep: "",
		street: "",
		addressNumber: "",
		district: "",
		city: "",
		uf: "",
		type: "",
		struct: "",
		year: "",
		totalUnits: "",
		towers: "",
		floors: "",
		elevators: "",
		blocks: "",
	},
	currentCondominiumUpdate: {},
	currentCondominiumUpdateIndex: null,
	condos: null,
	condominiumPage: {},
	viewMode: "grid",
	showModalCreate: false,
	showModalUpdate: false,
	filters: {
		type: null,
		struct: null,
	},
};

export const getters = {
	condos: (state) => state.condos,
	newCondominium: (state) => state.newCondominium,
	currentCondominium: (state) => state.currentCondominium,
};

export const mutations = {
	storeCondos(state, payload) {
		state.condos = payload;
	},
	pushCondos(state, payload) {
		state.condos.push(...payload);
	},
	storeCondominiumPage(state, payload) {
		state.condominiumPage = payload;
	},
	storeCondominium(state, payload) {
		state.condos.unshift(payload);
	},
	updateCondominium(state, { payload, index }) {
		const item = state.condos[index];
		Object.assign(item, payload);
	},
	removeCondominio(state, payload) {
		state.condos = state.condos.filter((condo) => condo.id != payload);
	},
	setViewMode(state, mode) {
		state.viewMode = mode;
	},
	toggleFilters(state, filters) {
		for (let filter in filters) {
			state.filters[filter] = filters[filter];
		}
	},
	toggleModalCreate(state) {
		state.showModalCreate = !state.showModalCreate;
	},
	toggleModalUpdate(state, { payload, index }) {
		state.currentCondominiumUpdate = payload;
		state.currentCondominiumUpdateIndex = index;
		state.showModalUpdate = !state.showModalUpdate;
	},
	storeCurrentCondominium(state, payload) {
		state.currentCondominium = payload;
	},
};

export const actions = {
	getCondos({ state, commit }) {
		let url = "/api/user/condos";

		if (state.filters.type != null) {
			url += `?type=${state.filters.type}`;
		}

		if (state.filters.type != null && state.filters.struct != null) {
			url += `&struct=${state.filters.struct}`;
		} else if (state.filters.struct != null) {
			url += `?struct=${state.filters.struct}`;
		}

		return api.get(url).then((response) => {
			commit("storeCondos", response.data.data);

			delete response.data["data"];
			commit("storeCondominiumPage", response.data);
		});
	},
	paginateGetCondos({ state, commit }, { nextPage }) {
		let url = `/api/user/condos?page=${nextPage}`;

		if (state.filters.type != null) {
			url += `&type=${state.filters.type}`;
		}

		if (state.filters.struct != null) {
			url += `&struct=${state.filters.struct}`;
		}

		return api.get(url).then((response) => {
			commit("pushCondos", response.data.data);

			delete response.data["data"];
			commit("storeCondominiumPage", response.data);
		});
	},
	search({ state, commit }, data) {
		let url = "/api/user/condos/search";

		if (state.filters.type != null) {
			url += `?type=${state.filters.type}`;
		}

		if (state.filters.type != null && state.filters.struct != null) {
			url += `&struct=${state.filters.struct}`;
		} else if (state.filters.struct != null) {
			url += `?struct=${state.filters.struct}`;
		}

		return api.post(url, data).then((response) => {
			commit("storeCondos", response.data);

			return response
		});
	},
	async createCondominium({ commit }, data) {
		return api
			.post("/api/user/condos", data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				commit("storeCondominium", response.data);
			});
	},
	updateCondominium({ commit }, { data, id, index }) {
		return api
			.put(`/api/user/condos/${id}`, { ...data, id })
			.then((response) => {
				commit("updateCondominium", {
					payload: response.data,
					index: index,
				});
			});
	},
	updateCondominiumCover({ commit }, { data, id, index }) {
		return api
			.post(`/api/user/condos/cover/${id}`, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				commit("updateCondominium", {
					payload: response.data,
					index: index,
				});
			});
	},
	removeCondominio({ commit }, condominiumId) {
		return api
			.delete(`/api/user/condos/${condominiumId}`)
			.then((response) => {
				if (response.status === 200) {
					commit("removeCondominio", condominiumId);
					return;
				}

				throw response;
			});
	},
	changeViewMode({ commit }, { mode }) {
		commit("viewMode", mode);
	},
	toggleModalUpdate({ commit }, data) {
		commit("toggleModalUpdate", data);
	},
	setCurrentCondominium({ commit }, data) {
		commit("storeCurrentCondominium", data);
	},
};
