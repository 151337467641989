import { api } from "../../services/api.js";

export const state = {
	inspections: [],
	inspectionsSearch: [],
	currentUpdate: {},
	currentUpdateIndex: null,
	page: null,

	scheduledInspections: [],
	pageScheduled: null,

	showModalCreate: false,
	showModalUpdate: false,
	viewMode: "grid",
};

export const getters = {
	inspections: (state) => state.inspections,
	inspectionsSearch: (state) => state.locationsSearch,
	scheduledInspections: (state) => state.scheduledInspections,
};

export const mutations = {
	storeAll(state, payload) {
		state.inspections = payload;
	},
	storePage(state, payload) {
		state.page = payload;
	},
	push(state, payload) {
		state.inspections.push(...payload);
	},
	store(state, payload) {
		state.inspections.push(payload);
	},
	storeSearch(state, payload) {
		state.inspectionsSearch = payload;
	},
	update(state, { payload, index }) {
		const item = state.inspections[index];
		Object.assign(item, payload);
	},
	remove(state, payload) {
		state.inspections = state.inspections.filter(
			(location) => location.id != payload
		);
	},

	storeScheduled(state, payload) {
		state.scheduledInspections = payload;
	},
	pushScheduled(state, payload) {
		state.scheduledInspections.push(...payload);
	},
	storePageScheduled(state, payload) {
		state.pageScheduled = payload;
	},

	toggleModalCreate(state) {
		state.showModalCreate = !state.showModalCreate;
	},
	toggleModalUpdate(state, { payload, index }) {
		state.currentUpdate = payload;
		state.currentUpdateIndex = index;
		state.showModalUpdate = !state.showModalUpdate;
	},

	toggleViewMode(state, mode) {
		state.viewMode = mode;
	}
};

export const actions = {
	get({ commit }, { nextPage, condoId }) {
		let url = `/api/user/inspections?condo_id=${condoId}`;

		if (nextPage != null) {
			url += `&page=${nextPage}`;
		}

		return api.get(url).then((response) => {
			if (nextPage != null) {
				commit("push", response.data.data);
			} else {
				commit("storeAll", response.data.data);
			}

			delete response.data["data"];
			commit("storePage", response.data);
		});
	},
	search({ commit }, data) {
		return api.post("/api/user/inspections/search", data).then((response) => {
			commit("storeSearch", response.data);

			return response
		});
	},
	getScheduled({ commit }, { nextPage, condoId }) {
		let url = `/api/user/inspections/scheduled?condo_id=${condoId}`;

		if (nextPage != null) {
			url += `&page=${nextPage}`;
		}

		return api.get(url).then((response) => {
			if (nextPage != null) {
				commit("pushScheduled", response.data.data);
			} else {
				commit("storeScheduled", response.data.data);
			}

			delete response.data["data"];
			commit("storePageScheduled", response.data);
		});
	},
	create({ commit }, data) {
		return api.post("/api/user/inspections", data).then((response) => {
			commit("store", response.data);
		});
	},
	update({ commit }, { data, id, index }) {
		return api
			.put(`/api/user/inspections/${id}`, { ...data, id })
			.then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	remove({ commit }, id) {
		return api.delete(`/api/user/inspections/${id}`).then((response) => {
			if (response.status === 200) {
				commit("remove", id);
				return;
			}

			throw response;
		});
	},
};
