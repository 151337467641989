import { api } from "../../services/api.js";

export const state = {
	assets: [],
	assetsSearch: [],
	currentUpdate: {},
	currentUpdateIndex: null,
	page: null,
	showModalCreate: false,
	showModalUpdate: false,
	viewMode: "list",
};

export const getters = {
	assets: (state) => state.assets,
	assetsSearch: (state) => state.assets,
};

export const mutations = {
	storeAll(state, payload) {
		state.assets = payload;
	},
	storePage(state, payload) {
		state.page = payload;
	},
	storeSearch(state, payload) {
		state.assetsSearch = payload;
	},
	push(state, payload) {
		state.assets.push(...payload);
	},
	store(state, payload) {
		state.assets.push(payload);
	},
	update(state, { payload, index }) {
		const item = state.assets[index];
		Object.assign(item, payload);
	},
	remove(state, payload) {
		state.assets = state.assets.filter(
			(location) => location.id != payload
		);
	},

	toggleModalCreate(state) {
		state.showModalCreate = !state.showModalCreate;
	},
	toggleModalUpdate(state, { payload, index }) {
		state.currentUpdate = payload;
		state.currentUpdateIndex = index;
		state.showModalUpdate = !state.showModalUpdate;
	},

	toggleViewMode(state, mode) {
		state.viewMode = mode;
	}
};

export const actions = {
	get({ commit }, { nextPage, condoId }) {
		let url = `/api/user/assets?condo_id=${condoId}`;

		if (nextPage != null) {
			url += `&page=${nextPage}`;
		}

		return api.get(url).then((response) => {
			if (nextPage != null) {
				commit("push", response.data.data);
			} else {
				commit("storeAll", response.data.data);
			}

			delete response.data["data"];
			commit("storePage", response.data);
		});
	},
	search({ commit }, data) {
		return api.post("/api/user/assets/search", data).then((response) => {
			commit("storeAll", response.data);

			return response;
		});
	},
	searchToHumanResource({ commit }, data) {
		return api.post("/api/user/assets/search", data).then((response) => {
			commit("storeSearch", response.data);
		});
	},
	create({ commit }, data) {
		return api.post("/api/user/assets", data, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		}).then((response) => {
			commit("store", response.data);
		});
	},
	update({ commit }, { data, id, index }) {
		return api
			.put(`/api/user/assets/${id}`, { ...data, id })
			.then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	updateCover({ commit }, { data, id, index }) {
		return api
			.post(`/api/user/assets/cover/${id}`, data, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				commit("update", { payload: response.data, index: index });
			});
	},
	remove({ commit }, id) {
		return api.delete(`/api/user/assets/${id}`).then((response) => {
			if (response.status === 200) {
				commit("remove", id);
				return;
			}

			throw response;
		});
	},
};
